import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    onAuthStateChanged,
    signInWithPopup,
    // signInWithEmailAndPassword,
    // createUserWithEmailAndPassword,
    // sendPasswordResetEmail,
    signOut 
} from "firebase/auth";
import {
    getFirestore,
    // query,
    // getDocs,
    // collection,
    // where,
    // addDoc,
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCBxscE7M4MHT-FNqPupQWEJPSXLYpBtYM",
    authDomain: "gikly-modules-test.firebaseapp.com",
    // authDomain: "auth.davidsepulveda.xyz",
    projectId: "gikly-modules-test",
    storageBucket: "gikly-modules-test.appspot.com",
    messagingSenderId: "718273764797",
    appId: "1:718273764797:web:cab8303be832a63f6e06d4",
    measurementId: "G-2QPH7MQLYE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async (callback) => {
    try {
        await signInWithPopup(auth, googleProvider);
        // const res = await signInWithPopup(auth, googleProvider);
        // const user = res.user;

        // console.log(user);

        // const q = query(collection(db, "users"), where("uid", "==", user.uid));
        // const docs = await getDocs(q);
        // if (docs.docs.length === 0) {
        //     await addDoc(collection(db, "users"), {
        //         uid: user.uid,
        //         name: user.displayName,
        //         authProvider: "google",
        //         email: user.email,
        //     });
        // }
        // console.log(callback)
        if (callback)
            callback();
    } catch (err) {
        console.error(err.message);
    }
};

const logout = () => {
    signOut(auth);
};




export {
    auth,
    db,
    signInWithGoogle,
    // logInWithEmailAndPassword,
    // registerWithEmailAndPassword,
    // sendPasswordReset,
    onAuthStateChanged,
    logout,
};


// https://blog.logrocket.com/user-authentication-firebase-react-apps/