import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import logo from './logo.svg';
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle, onAuthStateChanged, logout } from "./firebase";
// import Login from './Login.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

function App() {

  const [user, setUser] = useState(null);

  const fetchToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImJmMWMyNzQzYTJhZmY3YmZmZDBmODRhODY0ZTljMjc4ZjMxYmM2NTQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRGF2aWQgU2VwdWx2ZWRhLiIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQUZkWnVjb0NnS182dUxHR21jUU45Tk1NRHY2alJIaE5Rb01XSlhla1p1UHdQZz1zOTYtYyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9naWtseS1tb2R1bGVzLXRlc3QiLCJhdWQiOiJnaWtseS1tb2R1bGVzLXRlc3QiLCJhdXRoX3RpbWUiOjE2NTgyMDc1MDIsInVzZXJfaWQiOiJtdkJCTXFxdWFVWE9zMFdQNmJyQ0pHMVBRb3oxIiwic3ViIjoibXZCQk1xcXVhVVhPczBXUDZickNKRzFQUW96MSIsImlhdCI6MTY1ODIwNzUwMiwiZXhwIjoxNjU4MjExMTAyLCJlbWFpbCI6InZvY2VzY29sQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTAyNTcyOTc3NTQ4NjU3MTA2MTg3Il0sImVtYWlsIjpbInZvY2VzY29sQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.EKvUmis2Jxh-EOTqAoiW2aK4mAd2jWJN45QrMk7V_iSWidixGs9jhuFCU29W-t55Yiv253hwmJefjK5z2xrcWq-mDtfIkfmNRSG-R4YWg-Y-DH8bFoWAbvY8smTJ1DF6I1P0Qxqe1FtSvG-y2fYABpObxr-j9H5PkMQMkV6y8upaeENgvy1Px9_awEn8UQuL-xHYxWDln10QdkN66TLHIS6ItdKXKTEKqWpHoQv2gSar_FuvyLTXqyP8DErq0l9VqjV4KtNmbiTapvot1FaXM_BqcsHwIYBjf5EGNPpLQGxjQzJRvFyKy9VTpSBKwY9SssKtqmHd7ah05zWF_ZU3lw";

  onAuthStateChanged(auth, async (u) => {
    if (u) {

      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = u.uid;

      // const token = await u.getIdToken();
      // console.log(token);

      console.log("Access token", u.accessToken);

      // u.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      //   // Send token to your backend via HTTPS
      //   console.log('ID TOKEN', idToken);






      // }).catch(function (error) {
      //   // Handle error
      // });
    } else {
      console.log('No user');
    }
    setUser(u);
  });

  return (
    <div className="text-center text-dark">
      <h1>Gikly</h1>
      {
        user != null
          ?
          <>
            <h1>{user.displayName}</h1>
            <h6>{user.email}</h6>
            <img className="btn rounded-circle border border-secondary bg-secondary border-5 m-0 p-0" src={user.photoURL} /><p />
            <button className="btn btn-light" onClick={logout}>Logout</button>
          </>
          : <button className="btn btn-danger" onClick={() => { signInWithGoogle(() => { console.log('LOGED IN?') }) }}>Login with Google</button>
      }
      <button onClick={() => { fetch("https://gikly.davidsepulveda.xyz/HelloWorld/?token=" + fetchToken).then(data => console.log(data)); }}>Fetch</button>
    </div>
  );
}

export default App;
